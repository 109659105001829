import { select, selectAll } from 'd3-selection';
import { transition } from 'd3-transition';
import { line } from 'd3-shape';
import { drag } from 'd3-drag';
import { range } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';

const HILL_POINT_COLORS = [
  '#FB7185',
  '#E879F9',
  '#A78BFA',
  '#60A5FA',
  '#38BDF8',
  '#2DD4BF',
  '#4ADE80',
  '#FACC15',
  '#FB923C',
  '#F87171',
];

let width, height, margin, w, h

// const { width, height, margin } = {
//   // width: 1000,
//   // width: 960,
//   width: 720,
//   height: 220,
//   margin: {
//     top: 15,
//     // right: 240,
//     right: 20,
//     bottom: 35,
//     // left: 60,
//     left: 10,
//   },
// };

// const { width, height, margin } = sizes

// const w = width - margin.left - margin.right;
// const h = height - margin.top - margin.bottom;

let xScale, xAxis, yScale, yAxis

const mapToGraph = (x) =>
  50 * Math.sin((Math.PI / 50) * x - (1 / 2) * Math.PI) + 50;

export default {
  methods: {
    initChartProperties(fullSize) {
      if (fullSize) {
        // width = 760
        width = 800
        // height = 220
        // height = 200
        height = 180
        margin = {
          top: 15,
          // right: 120,
          right: 160,
          bottom: 25,
          left: 10,
        }
      } else {
        // width = 760
        width = 800
        height = 130
        margin = {
          top: 15,
          // right: 120,
          right: 160,
          bottom: 25,
          left: 10,
        }
      }

      w = width - margin.left - margin.right;
      h = height - margin.top - margin.bottom;

      xScale = scaleLinear().domain([0, 100]).range([0, w]);
      xAxis = axisBottom(xScale).ticks(0);
      yScale = scaleLinear().domain([0, 100]).range([h, 0]);
      yAxis = axisLeft(yScale).ticks(0);
    },
    clearChart() {
      select('svg.hill-chart>*').remove();
    },
    generateChartLines() {
      const svg = select('svg.hill-chart')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      svg
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${h})`)
        .call(xAxis);

      svg.append('g').attr('class', 'y axis').call(yAxis);

      const lineData = range(0, 100, 0.1).map((i) => ({
        x: i,
        y: mapToGraph(i),
      }));

      const newLine = line()
        .x((d) => xScale(d.x))
        .y((d) => yScale(d.y));

      svg.append('path').attr('class', 'line').datum(lineData).attr('d', newLine);

      svg
        .append('line')
        .attr('class', 'middle')
        .attr('x1', xScale(50))
        .attr('y1', yScale(0))
        .attr('x2', xScale(50))
        .attr('y2', yScale(100));

      svg
        .append('text')
        .attr('class', 'caption')
        .text('Figuring things out')
        .attr('x', xScale(25))
        .attr('y', h + 19);

      svg
        .append('text')
        .attr('class', 'caption')
        .text('Making it happen')
        .attr('x', xScale(75))
        .attr('y', h + 19);

      return svg
    },
    compareChartPoints(a, b) {
      // return a.key.compare(b.key)
      return a.key.localeCompare(b.key)
    },
    plotChartPoints(points, svg) {
      this.hillPoints = points.sort(this.compareChartPoints).map(({ key, title, color, x }, index) => {
        return {
          key,
          color: HILL_POINT_COLORS[index % HILL_POINT_COLORS.length],
          title,
          x: xScale(x),
          y: yScale(mapToGraph(x)),
        }
      });

      const vm = this
      const dragIt = drag().on(
        'drag',
        function (event, d) {
          vm.isDirty = true

          let x = event.x;
          if (x < 0) {
            x = 0;
          } else if (x > w) {
            x = w;
          }
          const inverted = xScale.invert(x);
          d.x = x;
          d.y = yScale(mapToGraph(inverted));
          select(this).attr('transform', `translate(${d.x}, ${d.y})`);
        }
      ).on('end', (event, d) => {
        // Not needed anymore.
        // this.$refs.snapshotForm.$recursiveUpdate()
      });


      // svg.selectAll('.group').remove();

      // // I can't find a better way to do this without it stuffing up the merge
      // if (group) {
      //   group = svg
      //     .selectAll('.group')
      //     .data(hillPoints.value, ({ key }: { key }) => key)
      //     .enter()
      //     .append('g')
      //     .merge(group)
      //     .attr('data-key', ({ key }: { key: string }) => key)
      //     .attr('class', 'group')
      //     .call(dragIt);
      // } else {
      //   group = svg
      //     .selectAll('.group')
      //     .data(hillPoints.value, ({ key }: { key: string }) => key)
      //     .enter()
      //     .append('g')
      //     .attr('data-key', ({ key }: { key: string }) => key)
      //     .attr('class', 'group')
      //     .call(dragIt);
      // }

      const group = svg
        .selectAll('.group')
        .data(this.hillPoints, ({ key }) => key)
        .enter()
        .append('g')
        .attr('data-key', ({ key }) => key)
        .attr('class', 'group')

      if (!this.readOnly) {
        group.call(dragIt);
      }

      // if (firstDraw) {
      //   svg
      //     .selectAll('.group')
      //     .transition()
      //     .duration(800)
      //     .attrTween('transform', function (d: CircleData) {
      //       return pathTween(d);
      //     });
      // }
      // else {
      //   svg
      //     .selectAll('.group')
      //     .attr('transform', (d: CircleData) => {
      //       return `translate(${d.x}, ${d.y})`;
      //     });
      // }

      // Disable animation because it's tricky to make it smooth when simultaneously loading the swimlanes.
      svg
        .selectAll('.group')
        .transition()
        .duration(0)
        // .duration(800)
        .attrTween('transform', (d) => {
          return this.pathTween(d);
        });

      group
        .append('circle')
        .attr('fill', (d) => d.color)
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', 8);

      group
        .append('text')
        .attr('fill', '#222')
        .text((d) => d.title.truncate(35))
        .attr('x', 10)
        .attr('y', 4);
    },
    bringToFront(key) {
      if (this.broughtToFrontKey === key) {
        this.clearBringToFront();
        return;
      }

      const DIMMED_OPACITY = '0.15';
      this.broughtToFrontKey = key;

      // Dim other stories
      selectAll('.group > circle')
        .filter((_, i, g) => {
          // @ts-ignore
          return g[i].parentElement.getAttribute('data-key') != key;
        })
        .transition()
        .duration(500)
        .style('opacity', DIMMED_OPACITY);
      selectAll('.group > text')
        .filter((_, i, g) => {
          // @ts-ignore
          return g[i].parentElement.getAttribute('data-key') != key;
        })
        .transition()
        .duration(500)
        .style('opacity', DIMMED_OPACITY);

      // Undim this story (in case it was previously dimmed)
      select(`.group[data-key='${key}'] > circle`)
        .transition()
        .duration(500)
        .style('opacity', '1');
      select(`.group[data-key='${key}'] > text`)
        .transition()
        .duration(500)
        .style('opacity', '1');
      select(`.group[data-key='${key}']`).raise(); // Bring to front
    },
    clearBringToFront() {
      this.broughtToFrontKey = '';

      selectAll('.group > circle')
        .transition()
        .duration(500)
        .style('opacity', '1');

      selectAll('.group > text')
        .transition()
        .duration(500)
        .style('opacity', '1');
    },
    pathTween(d) {
      return function (t) {
        const x = t * d.x;
        const inverted = xScale.invert(x);
        const y = yScale(mapToGraph(inverted));
        return `translate(${x},${y})`;
      };
    },
    getSnapshotPoints() {
      return this.hillPoints.map(({ title, key, color, x }) => ({
        key,
        x: xScale.invert(x),
        title,
      }));
    }
  }
}
